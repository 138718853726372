export const WORDS = [
  'sophie',
  'bitter',
  'kelsea',
  'vinyl',
  'miley',
  'bella',
  'cassette',
  'sting',
  'pisces',
  'girls',
  'sharks',
  'soon',
  'niall',
  'holiday',
  'boobs',
  'version',
  'laurence',
  'serial',
  'gflip',
  'rogue',
  'noreen',
  'riot',
  'simon',
  'forever',
  'guess',
  'princess',
  'growing', 
  'kito', 
  'upsahl',
  'girl',
  'vocals', 
  'paula',
  'tequila',
  'arcade', 
  'merch', 
  'fletcher', 
  'record', 
  'bobby',
  'bible',
  'queer', 
  'potrykus', 
  'venues', 
  'shots', 
  'macbook',
  'tits', 
  'spotify', 
  'cameron', 
  'dreams',  
  'elise', 
  'capitol', 
  'cherry', 
  'hayley', 
  'music', 
  'lakoda', 
  'sapphic', 
  'singer', 
  'healing',
  'duncan', 
  'ingrid', 
  'tapes', 
  'wasted',
  'stream',
  'stage', 
  'undrunk',
  'cari', 
  'artist', 
  'silence',
  'rayne', 
  'finding',
  'voice',
  'song', 
  'daniel', 
  'cowell', 
  'setlist',
  'daddy',
  'detiger',
  'feel',
  'papiccio',
  'sing', 
  'festival',
  'jersey', 
  'kiyoko', 
  'bonnaroo',
  'lover', 
  'radio',
  'nicholas', 
  'studio', 
  'youth', 
  'album',
  'believe',
  'muna',  
  'olivia', 
  'abdul',
  'concert',
  'andress',
  'tiddies',
  'tour'
]
